import React from "react"
import { Link } from "gatsby"

import SiteHeaderCSS from "./siteHeader.module.css"

const Header = ({ title }) => {
  return (
    <h1 className={SiteHeaderCSS.title}>
      <Link
        style={{
          boxShadow: `none`,
          color: `inherit`,
        }}
        to={`/`}
      >
        {title}
      </Link>
    </h1>
  )
}

export default Header
