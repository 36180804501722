import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    body: {
      fontFamily: "Roboto",
    },
    table: {
      fontFamily: "IBM Plex Mono",
      width: "88%",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: "80%",
    },
    a: {
      color: "inherit",
    },
    h1: {
      fontFamily: "Poppins",
      fontWeight: "800",
    },
    h3: {
      fontFamily: "Poppins",
      fontWeight: "600",
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
