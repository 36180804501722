import React from "react"

import Bio from "../components/bio"

import SiteHeader from "./siteHeader"

const Layout = ({ children, title, post }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "auto",
        alignContent: "center",
      }}
    >
      <SiteHeader title={title} />

      {children}
      <footer
        style={{
          flex: "0 0 100%",
        }}
      >
        © {new Date().getFullYear()} - <Bio />
      </footer>
    </div>
  )
}
export default Layout
